// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReferralCodeLogo-bg-img-container{
    width: 280px;
    height: 394px;
    /* padding-bottom: 20px; */
    /* margin-bottom: 20px; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
}
.ReferralCodeLogo-bg-img{
    /* position: absolute; */
    width: 217px;
    height: 325px;
    object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/pages/entranceScreenTemplate/referralCodeLogo.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,0BAA0B;IAC1B,yBAAyB;IACzB;;0BAEsB;AAC1B;AACA;IACI,wBAAwB;IACxB,YAAY;IACZ,aAAa;IACb,iBAAiB;AACrB","sourcesContent":[".ReferralCodeLogo-bg-img-container{\n    width: 280px;\n    height: 394px;\n    /* padding-bottom: 20px; */\n    /* margin-bottom: 20px; */\n    /* display: flex;\n    justify-content: center;\n    align-items: center; */\n}\n.ReferralCodeLogo-bg-img{\n    /* position: absolute; */\n    width: 217px;\n    height: 325px;\n    object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
