// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  /* --eerie-black: rgba(23, 23, 23, 1);
  --port-gore: rgba(34, 29, 75, 1);
  --keppel: rgba(57, 179, 175, 1);
  --royal-blue: rgba(65, 54, 241, 1);
  --cadet-blue: rgba(166, 179, 187, 0.596);
  --white-2: rgba(255, 255, 255, 0);
  --white-3: rgba(255, 255, 255, 0.349);
  --white: rgba(255, 255, 255, 1);

  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-m: 15px;
  --font-size-l: 16px;
  --font-size-xl: 28px;

  --font-family-inter: "Inter";
  --font-family-sfpro_text-semibold: "SFPro Text-Semibold"; */
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.default-font-setting {
  color: var(--white);
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE;;;;;;;;;;;;;;;;6DAgB2D;AAC7D;;;AAGA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[":root {\n  /* --eerie-black: rgba(23, 23, 23, 1);\n  --port-gore: rgba(34, 29, 75, 1);\n  --keppel: rgba(57, 179, 175, 1);\n  --royal-blue: rgba(65, 54, 241, 1);\n  --cadet-blue: rgba(166, 179, 187, 0.596);\n  --white-2: rgba(255, 255, 255, 0);\n  --white-3: rgba(255, 255, 255, 0.349);\n  --white: rgba(255, 255, 255, 1);\n\n  --font-size-xs: 12px;\n  --font-size-s: 14px;\n  --font-size-m: 15px;\n  --font-size-l: 16px;\n  --font-size-xl: 28px;\n\n  --font-family-inter: \"Inter\";\n  --font-family-sfpro_text-semibold: \"SFPro Text-Semibold\"; */\n}\n\n\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.default-font-setting {\n  color: var(--white);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
