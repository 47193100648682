// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.CarouselWrapper {
  width: 250px;
  font-family: sans-serif;
  text-align: center;
  align-items: end;
  overflow: hidden;
}
.inner {
  white-space: nowrap;
  transition: transform 0.3s;
  width: 100%;
  /* width: 250px;
  overflow: hidden; */
}

.dark-bg {
  background-color: black;
  height: 100vh;
  width: 100%;
}

.carousel {
  /* height: 185px; */
  /* background-color: salmon; */
  justify-content: center;
  align-items: center;
  display: inline-flex;
  width: 250px;
}

button {
  margin-right: 5px;
}

button.active {
  background-color: salmon;
}
`, "",{"version":3,"sources":["webpack://./src/pages/testing/testing.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,8BAA8B;EAC9B,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,kCAAkC;AACpC;;AAEA;EACE,KAAK,uBAAuB,EAAE;EAC9B,OAAO,yBAAyB,EAAE;AACpC;;AAEA;EACE,YAAY;EACZ,uBAAuB;EACvB,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,0BAA0B;EAC1B,WAAW;EACX;qBACmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,8BAA8B;EAC9B,uBAAuB;EACvB,mBAAmB;EACnB,oBAAoB;EACpB,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".loader {\n  border: 16px solid #f3f3f3;\n  border-top: 16px solid #3498db;\n  border-radius: 50%;\n  width: 120px;\n  height: 120px;\n  animation: spin 2s linear infinite;\n}\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}\n\n.CarouselWrapper {\n  width: 250px;\n  font-family: sans-serif;\n  text-align: center;\n  align-items: end;\n  overflow: hidden;\n}\n.inner {\n  white-space: nowrap;\n  transition: transform 0.3s;\n  width: 100%;\n  /* width: 250px;\n  overflow: hidden; */\n}\n\n.dark-bg {\n  background-color: black;\n  height: 100vh;\n  width: 100%;\n}\n\n.carousel {\n  /* height: 185px; */\n  /* background-color: salmon; */\n  justify-content: center;\n  align-items: center;\n  display: inline-flex;\n  width: 250px;\n}\n\nbutton {\n  margin-right: 5px;\n}\n\nbutton.active {\n  background-color: salmon;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
