// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sigdao-icon-container {
  position: relative;
}


.sigdao-icon-container div {
  border-radius: 10.76px;
}

.sigdao-icon-white-bg {
  background-color: var(--white);
  border-radius: 8.26px;
  height: 100%;
  /* left: 0px; */
  position: absolute;
  /* top: 0px; */
  width: 100%;
}

.sigdao-icon-bg {
  background: linear-gradient(224.44deg, #00ffa3 5.74%, #ff00ff 87.84%);
  background-color: transparent;
  border-radius: 8.03px;
  height: 97.18%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  /* left: 1.41%; */
  position: absolute;
  /* top: 1.41%; */
  width: 97.18%;
}

.sigdao-icon {  
  background-color: transparent;
  height: 54.68%;
  /* left: 25.90%; */
  object-fit: cover;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 47.30%;
}`, "",{"version":3,"sources":["webpack://./src/components/icon.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;;AAGA;EACE,sBAAsB;AACxB;;AAEA;EACE,8BAA8B;EAC9B,qBAAqB;EACrB,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,cAAc;EACd,WAAW;AACb;;AAEA;EACE,qEAAqE;EACrE,6BAA6B;EAC7B,qBAAqB;EACrB,cAAc;EACd,SAAS;EACT,QAAQ;EAGR,4CAA4C;EAC5C,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,6BAA6B;EAC7B,cAAc;EACd,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,SAAS;EACT,QAAQ;EAGR,4CAA4C;EAC5C,aAAa;AACf","sourcesContent":[".sigdao-icon-container {\n  position: relative;\n}\n\n\n.sigdao-icon-container div {\n  border-radius: 10.76px;\n}\n\n.sigdao-icon-white-bg {\n  background-color: var(--white);\n  border-radius: 8.26px;\n  height: 100%;\n  /* left: 0px; */\n  position: absolute;\n  /* top: 0px; */\n  width: 100%;\n}\n\n.sigdao-icon-bg {\n  background: linear-gradient(224.44deg, #00ffa3 5.74%, #ff00ff 87.84%);\n  background-color: transparent;\n  border-radius: 8.03px;\n  height: 97.18%;\n  left: 50%;\n  top: 50%;\n  -webkit-transform: translateX(-50%) translateY(-50%);\n  -moz-transform: translateX(-50%) translateY(-50%);\n  transform: translateX(-50%) translateY(-50%);\n  /* left: 1.41%; */\n  position: absolute;\n  /* top: 1.41%; */\n  width: 97.18%;\n}\n\n.sigdao-icon {  \n  background-color: transparent;\n  height: 54.68%;\n  /* left: 25.90%; */\n  object-fit: cover;\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  -webkit-transform: translateX(-50%) translateY(-50%);\n  -moz-transform: translateX(-50%) translateY(-50%);\n  transform: translateX(-50%) translateY(-50%);\n  width: 47.30%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
